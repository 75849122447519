import React from 'react';
import { Map, Marker, Popup, TileLayer } from 'react-leaflet'
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faMapPin } from '@fortawesome/free-solid-svg-icons';
// import L from 'leaflet';

class MyMap extends React.Component{
    render(){
        const { options } = this.props;
        const position = [25.175067, 55.344514];
        

        if(typeof window !== 'undefined'){
            return(
                <>
                    <Map center={position} zoom={13} {...options} className="d-none d-md-block">
                        <TileLayer
                            url="https://api.mapbox.com/styles/v1/drgnlrd/ckfjwl9k22l1e19ntw4nnhgjv/tiles/256/{z}/{x}/{y}@2x?access_token=pk.eyJ1IjoiZHJnbmxyZCIsImEiOiJja2Zqc2o0N3QwYzlzMnJuenkxNHoycDJ3In0._LAS1ff-jXTVEUWptCCKLQ"
                            attribution="Map data &copy; <a href=&quot;https://www.openstreetmap.org/&quot;>OpenStreetMap</a> contributors, <a href=&quot;https://creativecommons.org/licenses/by-sa/2.0/&quot;>CC-BY-SA</a>, Imagery &copy; <a href=&quot;https://www.mapbox.com/&quot;>Mapbox</a>"
                        />
                        {/*mapbox://styles/drgnlrd/ckfjwl9k22l1e19ntw4nnhgjv*/}
                        <Marker position={position} >
                            <Popup>Naqiyah Packaging Materials Trading LLC. <br/> Warehouse no 27, <br/>Fakhruddn Properties, 21A street <br/>Ras Al Khor Ind. Area 2 <br/> United Arab Emirates </Popup>
                        </Marker>
                    </Map>
                    
                    <Map center={position} zoom={13} dragging={false} {...options} className="d-sm-block d-md-none">
                        <TileLayer
                            url="https://api.mapbox.com/styles/v1/drgnlrd/ckfjwl9k22l1e19ntw4nnhgjv/tiles/256/{z}/{x}/{y}@2x?access_token=pk.eyJ1IjoiZHJnbmxyZCIsImEiOiJja2Zqc2o0N3QwYzlzMnJuenkxNHoycDJ3In0._LAS1ff-jXTVEUWptCCKLQ"
                            attribution="Map data &copy; <a href=&quot;https://www.openstreetmap.org/&quot;>OpenStreetMap</a> contributors, <a href=&quot;https://creativecommons.org/licenses/by-sa/2.0/&quot;>CC-BY-SA</a>, Imagery &copy; <a href=&quot;https://www.mapbox.com/&quot;>Mapbox</a>"
                        />
                        {/*mapbox://styles/drgnlrd/ckfjwl9k22l1e19ntw4nnhgjv*/}
                        <Marker position={position} >
                            <Popup>Naqiyah Packaging Materials Trading LLC. <br/> Warehouse no 27, <br/>Fakhruddn Properties, 21A street <br/>Ras Al Khor Ind. Area 2<br/> United Arab Emirates </Popup>
                        </Marker>
                    </Map>
                </>
            )
        }

        return null;
    }
}

export default MyMap;
