import React from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapMarkerAlt, faPhoneAlt, faEnvelope, faMap, faPhoneSquareAlt } from '@fortawesome/free-solid-svg-icons';
import { faWhatsappSquare, faInstagramSquare } from '@fortawesome/free-brands-svg-icons';
import MyMap from './map';

class Contact extends React.Component{
    render(){
        return(
            <>
                <Container className="mb-5">
                    <Row className="mt-4">
                        <Col sm={12} className="text-center color-primary" data-aos="fade-up" >
                            <h2 className="heading d-none d-md-block" >Get In Touch</h2>
                            <h2 className="heading d-sm-block d-md-none" >Get In Touch</h2>
                            <hr className="mb-4 bar-mobile-p " />
                        </Col>
                    </Row>
                    <Row className="mt-3">
                        
                        <Col sm={6} className="d-none d-md-block">
                            <MyMap />
                        </Col>

                        <Col sm={6} className="mt-2 text-left">

                        <div className="text-center">
                            <FontAwesomeIcon icon={ faPhoneAlt } size="2x" className="mb-2 align-top color-primary d-xs-inline-block d-md-none" />
                            <p className="d-sm-inline-block d-md-none">+97143905444</p>
                        </div>
                        

                        <FontAwesomeIcon icon={ faPhoneAlt } size="lg" className="align-top color-primary d-none d-md-inline-block" /> 
                        <p className="d-none d-md-inline-block"> &nbsp; +97143905444</p> 
                        
                        <br/>
                        
                        <div className="text-center">
                            <FontAwesomeIcon icon={ faEnvelope } size="2x" className="mb-2 align-top color-primary d-xs-inline-block d-md-none" />
                            <p className="d-sm-inline-block d-md-none">sales@naqiyahpack.com</p>
                        </div>

                        <FontAwesomeIcon icon={ faEnvelope } size="lg" className="align-top color-primary d-none d-md-inline-block" />
                        <p className="d-none d-md-inline-block"> &nbsp;  sales@naqiyahpack.com</p>
                        
                        <br/>

                        <div className="text-center">
                            <FontAwesomeIcon icon={ faMap } size="2x" className="mb-2 align-top color-primary d-xs-inline-block d-md-none" />
                            <p className="d-sm-inline-block d-md-none">Warehouse no 27, Fakhruddn Properties, 21A street Ras Al Khor Ind. Area 2, United Arab Emirates</p>
                        </div>

                        <FontAwesomeIcon icon={ faMap } size="lg" className="align-top color-primary d-none d-md-inline-block" /> 
                        <p className="d-none d-md-inline-block "> &nbsp;  Warehouse no 27,<br/> &nbsp;Fakhruddn Properties, 21A street, <br/> &nbsp;Ras Al Khor Ind. Area 2 <br/> &nbsp; United Arab Emirates</p>
                        
                        
                        <div className="mt-4 mb-5 text-center d-sm-block d-md-none">
                            <Button variant="outline-primary " href="https://maps.app.goo.gl/QmfuAVketU28C2D1A" target="_blank" className="custom-btn-outline color-primary font-weight-bold" size="lg"> <FontAwesomeIcon size="lg" icon={faMapMarkerAlt} /> &nbsp; Get Directions </Button>
                        </div>

                        <div className="mt-3 mb-5 d-none d-md-block">
                            <Button variant="outline-primary " href="https://maps.app.goo.gl/QmfuAVketU28C2D1A" target="_blank" className=" custom-btn-outline color-primary font-weight-bold" > <FontAwesomeIcon icon={faMapMarkerAlt} /> &nbsp; Get Directions </Button>
                        </div>
                       

                        <div className="mt-4 mb-5 text-center d-sm-block d-md-none">
                            <h4 className="color-primary font-weight-bold">Connect with us:- </h4>
                            <div className="mt-3">
                                <a href="http://www.instagram.com/naqiyahpackaging" aria-label="instagram" rel="noreferrer" target="_blank"><FontAwesomeIcon icon={faInstagramSquare} size="3x" className="mx-3" style={{'color':'#dd2a7b'}} /></a>
                                
                                <a href="https://wa.me/971582615753" aria-label="whatsapp" rel="noreferrer" target="_blank"><FontAwesomeIcon icon={faWhatsappSquare} size="3x" className="mx-3" style={{'color':'#25d366'}} /></a>
                                
                                <a href="mailto:sales@naqiyahpack.com" aria-label="email" rel="noreferrer" target="_blank"><FontAwesomeIcon icon={faEnvelope} size="3x" className="mx-3" style={{'color':'#d44638'}} /></a>
                                
                                <a href="tel:+97143905444" aria-label="phone" rel="noreferrer" target="_blank"><FontAwesomeIcon icon={faPhoneSquareAlt} size="3x" className="mx-3" style={{'color':'grey'}} /></a>
                                
                            </div>
                        </div>
                        <div className="mt-5 mb-5 d-none d-md-inline">
                            <h5 className="color-primary font-weight-bold">Connect with us:- </h5>
                            <div className="mt-3">
                                <a href="http://www.instagram.com/naqiyahpackaging" aria-label="instagram" rel="noreferrer" target="_blank"><FontAwesomeIcon icon={faInstagramSquare} size="2x" className="mr-4" style={{'color':'#dd2a7b'}} /></a>
                                
                                <a href="https://wa.me/971582615753" aria-label="whatsapp" rel="noreferrer" target="_blank"><FontAwesomeIcon icon={faWhatsappSquare} size="2x" className="mr-4" style={{'color':'#25d366'}} /></a>
                                
                                <a href="mailto:sales@naqiyahpack.com" aria-label="email" rel="noreferrer" target="_blank"><FontAwesomeIcon icon={faEnvelope} size="2x" className="mr-4" style={{'color':'#d44638'}} /></a>
                                
                                <a href="tel:+97143905444" aria-label="phone" rel="noreferrer" target="_blank" ><FontAwesomeIcon icon={faPhoneSquareAlt} size="2x" className="mr-4" style={{'color':'grey'}} /></a>
                                
                            </div>
                        </div>

                        </Col>
                        
                        <Col sm={6} className="d-sm-block d-md-none">
                            <MyMap />
                        </Col>
                        
                    </Row>
                </Container>
            </>
        )
    }
}

export default Contact;
